import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';

const heroSlider = document.getElementById('js-hero-slider');

const heroSlide = new Swiper(heroSlider, {
    slidesPerView: 1,
    modules: [Navigation, Pagination, EffectFade, Autoplay],
    effect: 'fade',
    crossFade: true,
    autoplay: {
        delay: 4000,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
    },
});
